import axios from 'axios'
import qs from 'qs'
import { message } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.min.css'

axios.defaults.baseURL = process.env.NODE_ENV != 'development' ? process.env.VUE_APP_URL: ''
axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded;charset=UTF-8'

// console.log(axios.CancelToken.source().token);

axios.defaults.transformRequest = [
  function (data, config) {
    if (config['Content-Type'] == 'multipart/form-data') {
      return data
    } else {
      return qs.stringify(data)
    }
  }
]
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // config.headers.post['Link'] = 'ms.digaokeji.cn'
    // 请求token
    let token = localStorage.getItem('token')
    if (token) {
      config.headers.common['token'] = localStorage.getItem('token')
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 相应拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code === -1) {
      message.error(response.data.msg + response.data?.data)
      return Promise.reject(response)
    } else {
      return Promise.resolve(response)
    }
  },
  error => {
     let msgArr = ['有问题啦，快让后台看一眼','后台给你抛了个错误就溜啦', '后台向你扔了个错误炸弹！', '后台半夜跑路啦']
     let msg = '500: ' + msgArr[Math.floor(Math.random() * msgArr.length)];
     
    switch (error.response.status) {
      case 500:
        message.error(msg);
        break;
      default:
        console.log(error);
    }
    return Promise.reject(error)
  }
)

export default axios