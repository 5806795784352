import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
    state: {
        color1: ['#5AE0FB', '#4389F9'], //echart-颜色
        color2: ['#FFA632', '#FF6999'], //echart-颜色
        color3: ['#4389F9', '#5AE0FB', '#FFA632', '#FF6999', '#7AF993'], //echart-颜色
        grid: { //echart-布局
            left: '3%',
            right: '3%',
            bottom: '8%',
            containLabel: true
        },
        axisPointer: {
            type: 'shadow'
        },
        gridtop: { //echart-布局
            left: '3%',
            right: '3%',
            bottom: '3%',
            top: '4%',
            containLabel: true
        },
        axisLinel: {
            show: true,
            textStyle: {
                color: "#000"
            }
        },
        axisLine: {
            lineStyle: {
                color: 'rgba(0,0,0,0.1)',
                width: 1, //这里是为了突出显示加上的  
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: "#000"
            }
        },
        splitLine: {
            show: true,
            lineStyle: { //X轴网格线型样式
                color: "rgba(0,0,0,0.05)",
                width: 1
            }
        },
    }
})

export default store