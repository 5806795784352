<template>
  <a-layout class="layout">
    <a-layout-sider :collapsed="isCollapsed" class="sider" width="240">
      <div class="sider-title">
        <img src="@/assets/images/logo.png" alt="logo" />
        <span v-show="!isCollapsed">的高平台管理系统</span>
      </div>
      <!-- 菜单 -->
      <a-menu theme="dark" mode="inline" v-model="current">
        <template v-for="menuItem in menu">
          <a-sub-menu v-if="menuItem.children" :key="menuItem.name">
            <template slot="title">
              <a-icon :type="menuItem.icon" />
              <span>{{ menuItem.name }}</span>
            </template>
            <a-menu-item
              v-for="subItem in menuItem.children"
              :key="subItem.name"
            >
              <router-link :to="subItem.url">{{ subItem.name }}</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item v-else :key="menuItem.name">
            <router-link :to="menuItem.url">
              <a-icon :type="menuItem.icon" />
              <span>{{ menuItem.name }}</span>
            </router-link>
          </a-menu-item>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout class="content">
      <a-layout-header>
        <span class="menu-trigger" @click="isCollapsed = !isCollapsed">
          <a-icon :type="isCollapsed ? 'menu-unfold' : 'menu-fold'"></a-icon>
        </span>
        <div>
          <!-- <a-button icon="bell"></a-button>
          <a-button icon="setting"></a-button> -->
          <span>
            <img v-if="avatar" class="img-top" :src="avatar" alt="">
            <a-avatar v-else icon="user"></a-avatar>
            {{ username }}
          </span>
          <a-button type="link" icon="poweroff" @click="logout()"
            >退出登录</a-button
          >
        </div>
      </a-layout-header>
      <!-- 面包屑 -->
      <!-- <a-breadcrumb :routes="routes">
        <template slot="itemRender" slot-scope="{ route, params, routes, paths }">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{ route.breadcrumbName }}
          </span>
          <router-link v-else :to="`${basePath}/${paths.join('/')}`">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb> -->
      <!-- 内容展示 -->
      <a-layout-content>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <a-back-top :target="scrollTarget" />
      </a-layout-content>
      <a-layout-footer> Copyright © 2018-2021 的高科技 </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
      current: ['首页'],
      isCollapsed: false,
      menuList: [
        {
          name: '首页',
          icon: 'home',
          url: '/'
        },
        {
          name: '客户管理',
          icon: 'team',
          children: [
            { name: '公司管理', url: '/client/company' }
          ]
        },
        {
          name: '课件管理',
          icon: 'book',
          children: [
            { name: '课件列表', url: '/courseware/courselist' }
          ]
        },
        {
          name: '设备管理',
          icon: 'android',
          children: [
            { name: '设备激活', url: '/equipment/device' },
            { name: '版本管理', url: '/equipment/version' }
          ]
        },
        {
          name: '微信公众号管理',
          icon: 'aliwangwang',
          children: [
            { name: '图文管理', url: '/wechat/graphic' }
          ]
        },
        {
          name: '系统设置',
          icon: 'setting',
          children: [
            { name: '账号管理', url: '/system/account' },
            { name: '角色管理', url: '/system/role' },
            { name: '菜单管理', url: '/system/menu' }
          ]
        }
      ]
    }
  },
  computed: {
    avatar() {
      return localStorage.getItem('avatar')
    },
    username() {
      return localStorage.getItem('username') ? localStorage.getItem('username') : 'ADMIN'
    },
    menu() {
      return localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : this.menuList //线上数据
      // return this.menuList //本地目录
    }
  },
  methods: {
    scrollTarget() {
      return document.querySelector('.ant-layout-content')
    },
    logout() {
      this.api.logout().then(res => {
        this.$message.success(res.data.msg)
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="less">
.img-top{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}
.layout {
  width: 100vw;
  height: 100vh;
}
.sider {
  // position: fixed;
  height: 100vh;
  overflow: auto;
  left: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.sider-title {
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  color: #fff;
  img {
    width: 32px;
    height: 32px;
  }
  span {
    display: inline-block;
    font-size: 17px;
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
header.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #dfdfdf;
  line-height: 1;
  padding: 0 20px;
  .menu-trigger {
    font-size: 24px;
    cursor: pointer;
  }
}
.ant-breadcrumb {
  height: 48px;
  line-height: 48px;
  padding-left: 24px;
}
main.ant-layout-content {
  padding: 30px 24px;
  min-height: unset;
  width: calc(100% - 20px);
  overflow: auto;
}
.ant-layout-footer {
  text-align: center;
  color: #9c9b9b;
}
</style>
