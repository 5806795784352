<template>
  <div class="login">
    <a-row 
      class="cover-mask"
      type="flex" 
      align="middle" 
      justify="center">
      <a-form-model
        ref="loginForm"
        :model="form"
        :rules="rules">
        <h2>的高科技平台管理系统</h2>
        <a-form-model-item prop="username">
          <a-input 
            v-model.trim="form.username" 
            size="large" 
            placeholder="用户名">
            <a-icon slot="prefix" type="user"></a-icon>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input-password 
            v-model.trim="form.password" 
            size="large" 
            placeholder="密码">
            <a-icon slot="prefix" type="lock"></a-icon>
          </a-input-password>
        </a-form-model-item>
        <a-checkbox :checked="form.remember" @change="rememberAccount">记住密码</a-checkbox>
        <a-button
          type="primary" 
          size="large" 
          block 
          @click="login()">登 录</a-button>
      </a-form-model>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: '',
        password: '',
        remember: false
      },
      rules: {
        username: [
          { required: true, message: ' ', trigger: 'blur'}
        ],
        password: [
          { required: true, message: ' ', trigger: 'blur'}
        ]
      }
    }
  },
  mounted () {
    if (localStorage.getItem('account')) {
      this.form = JSON.parse(localStorage.getItem('account'))
    }
  },
  methods: {
    login () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.api.login(this.form).then(res => {
            console.log('登录',res)
            localStorage.setItem('avatar', res.data.data.avatar)
            localStorage.setItem('username', res.data.data.uname)
            localStorage.setItem('token', res.data.data.token)
            localStorage.setItem('menu', JSON.stringify(res.data.data.menulist))
            localStorage.setItem('route', JSON.stringify(res.data.data.route))
            this.$message.success(res.data.msg)
            this.$router.push('/')
            // 记住密码
            if (this.form.remember) {
              localStorage.setItem('account', JSON.stringify(this.form))
            } else {
              localStorage.removeItem('account')
            }
          })
        } else {
          this.$message.warning('用户名或密码不能为空')
          return false;
        }
      })
    },
    rememberAccount (e) {
      this.form.remember = e.target.checked
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('../assets/images/bg-login.jpg') center center no-repeat;
  background-size: cover;
}
.cover-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#000000, 0.3);
}
.ant-form {
  width: 360px;
  padding: 50px 24px;
  border: 1px solid rgba(#fff, 0.4);
  background-color: rgba(#fff, 0.2);
  border-radius: 4px;
  h2 {
    text-align: center;
    margin-bottom: 50px;
    font-weight: bold;
    color: #fff;
  }
}
.ant-checkbox-wrapper {
  margin: 0 0 12px;
  color: #fff;
}
button.ant-btn {
  margin: 0;
}

</style>