import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '/',
        name: 'homePage',
        component: () => import('../views/homePage/index.vue')
      },
      {
        path: 'client/company',
        name: 'Company',
        component: () => import('../views/clients/Company.vue')
      },
      {
        path: 'courseware/courselist',
        name: 'CourseList',
        component: () => import('../views/courseware/CourseList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'courseware/detail',
        name: 'CourseDetail',
        component: () => import('../views/courseware/CourseDetail.vue')
      },
      {
        path: 'equipment/device',
        name: 'Device',
        component: () => import('../views/equipment/Device.vue')
      },
      {
        path: 'equipment/version',
        name: 'Version',
        component: () => import('../views/equipment/Version.vue')
      },
      {
        path: 'system/account',
        name: 'Account',
        component: () => import('../views/system/Account.vue')
      },
      {
        path: 'system/role',
        name: 'Role',
        component: () => import('../views/system/Role.vue')
      },
      {
        path: 'system/menu',
        name: 'Menu',
        component: () => import('../views/system/Menu.vue')
      },
      {
        path: 'system/routeconfig',
        name: 'RouteConfig',
        component: () => import('../views/system/RouteConfig.vue')
      },
      {
        path: 'wechat/graphic',
        name: 'graphic',
        component: () => import('../views/wechat/graphic/index.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0}
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(r => r.meta.requireAuth)) {
    if (localStorage.getItem('token')) {
      let routeList = localStorage.getItem('route')? JSON.parse(localStorage.getItem('route')) : [] // 获取登录后的路由数组
      let mainRoutes = routeList.map(item => {
        return { path: item.path, name: item.name, component: () => import(item.import)}
      })
      if (to.name == 'CourseDetail') {
        from.meta.keepAlive = true
      } else {
        from.meta.keepAlive = false
      }
      next()
    } else {
      next({
        path: '/login'
      })      
    }
  } else {
    next()
  }
})


export default router
