import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AntDesign from 'ant-design-vue'
import 'ant-design-vue/dist/antd.min.css'
import './public/public.less'
import api from './utils/api'
import store from './store'
import pattern from './utils/pattern'
import common from './public/common'
import svgIcon from './components/svg.vue'
Vue.component('svg-icon', svgIcon)
// 全局加载svg，不用没次都import
const req = require.context('./assets/svg', false, /\.svg$/)
const requireAll = requireContent => requireContent.keys().map(requireContent)
requireAll(req)

import echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

Vue.prototype.api = api
Vue.prototype.pattern = pattern
Vue.prototype.global = common

Vue.use(AntDesign)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
