import axios from './axios'

export default {
  login (param) {
    return axios.post('/official/login/login', param)
  },
  logout (param) {
    return axios.post('/official/login/logout', param)
  },
  /**
   * 公共api
   */
  // 上传文件
  uploadFile (file, type, config = {}) {
    let formData = new FormData()
    formData.append('file', file)
    if (type) {
      formData.append('type', type)
    }
    return axios.post('/official/base/fileUpload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      ...config
    })
  },
  // 所有权限
  allRoles (param) {
    return axios.post('/official/group/getGroup', param)
  },
  // 所有公司
  allCompanies (param) {
    return axios.post('/official/company/coAll', param)
  },
  // 所有工种
  allWorkTypes (param) {
    return axios.post('/official/work_type/wtAll', param)
  },
  // 所有层级
  allLevels(param) {
    return axios.post('/official/work_type/levelAll', param)
  },

  /**
   * 客户管理
   */

  // 公司列表
  getCompanyList (param) {         
    return axios.post('/official/company/index', param)
  },
  addCompany (param) {
    return axios.post('/official/company/add', param)
  },
  editCompany (param) {
    return axios.post('/official/company/edit', param)
  },
  companyInfo (param) {
    return axios.post('/official/company/info', param)
  },
  // 切换启用/停用状态
  switchCompanyStatus (param) {   
    return axios.post('/official/company/setStatus', param)
  },
  // 清除公司数据
  clearCompanyData (param) {      
    return axios.post('/official/company/setClear', param)
  },
  
  /**
   * 课件管理
   */

   
  // 课件列表
  getCourseList (param) {
    return axios.post('/official/cocw/index', param)
  },
  addCourse (param) {
    return axios.post('/official/cocw/add', param)
  },
  editCourse (param) {
    return axios.post('/official/cocw/edit', param)
  },
  getCourseInfo (param) {
    return axios.post('/official/cocw/info', param)
  },
  delCourse (param) {
    return axios.post('/official/cocw/delete', param)
  },

  addQues (param) {
    return axios.post('/official/cocw/cwExamAdd', param)
  },
  editQues (param) {
    return axios.post('/official/cocw/cwExamEdit', param)
  },
  getQuesInfo (param) {
    return axios.post('/official/cocw/cwExamInfo', param)
  },
  /**
   * 设备管理 
   */

  
  // 设备激活
  getDeviceList (param) { 
    return axios.post('/official/device/deviceList', param)
  },
  delDevice (param) {
    return axios.post('/official/device/deviceDel', param)
  },
  bindDevice (param) {
    return axios.post('/official/device/deviceBind', param)
  },


  // 版本管理
  getVersionList (param) {
    return axios.post('/official/appver/deviceVer', param)
  },
  addVersion (param) {
     return axios.post('/official/appver/deviceVerAdd', param)
  },
  delVersion (param) {
    return axios.post('/official/appver/deviceVerDel', param)
  },
  getCompanyProject (param) {
    return axios.post('/official/company/getProjectList', param)
  },
  /**
   * 系统设置 
   */


  // 账号管理
  getAccountList (param) {
    return axios.post('/official/manager/index', param)
  },
  addAccount (param) {
    return axios.post('/official/manager/add', param)
  },
  editAccount (param) {
    return axios.post('/official/manager/edit', param)
  },
  getAccountInfo (param) {
    return axios.post('/official/manager/info', param)
  },
  delAccount (param) {
   return axios.post('/official/manager/del', param)
  },


  // 角色管理
  getRoleList (param) {
    return axios.post('/official/group/index', param)
  },
  addRole (param) {
    return axios.post('/official/group/add', param)
  },
  editRole (param) {
    return axios.post('/official/group/edit', param)
  },
  getRoleInfo (param) {
    return axios.post('/official/group/info', param)
  },
  delRole (param) {
    return axios.post('/official/group/del', param)
  },
  switchRoleStatus (param) {
    return axios.post('/official/group/setstatus', param)
  },


  // 路由配置列表
  getRouteConfigList (param) {
    return axios.post('/official/home/platroute', param)
  },
  // 首页-公司项目
  getProjectList (param) {
    return axios.post('/official/company/getCompanyProjectList', param)
  },

  // 菜单管理
  getMenuList (param) {
    return axios.post('/official/menu/menuAll', param)
  },
  addMenu (param) {
    return axios.post('/official/menu/add', param)
  },
  editMenu (param) {
    return axios.post('/official/menu/edit', param)
  },
  getMenuInfo (param) {
    return axios.post('/official/menu/info', param)
  },
  delMenu (param) {
    return axios.post('/official/menu/del', param)
  }
}